import React, { Component } from "react";
import Jumbotran from "./Jumbotran";
import NavBar from "./NavBar";

export default class Header extends Component {
    render() {
        let resumeData = this.props.resumeData;
        return (
            <React.Fragment>
                <header
                    id="home"
                    style={{
                        minHeight: `${window.innerHeight}px`,
                    }}
                >
                    <NavBar />
                    <Jumbotran />
                </header>
            </React.Fragment>
        );
    }
}
