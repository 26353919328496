let resumeData = {
  imagebaseurl: "https://rbhatia46.github.io/",
  name: "Sudarshan Uprety",
  role: "Frontend Developer and Data Scientist",
  city: "Scarborough",
  state: "Ontario",
  country: "Canada",
  phone: "+1 647-542-9633",
  email: "sudarshanupretyofficial@gmail.com",
  skypeid: "Your skypeid",
  socialLinks: [
    {
      name: "linkedin",
      url: "https://www.linkedin.com/in/sudarshan-uprety-134a0b160/",
      className: "fa fa-linkedin",
    },
    {
      name: "github",
      url: "https://github.com/sudarshanuprety42",
      className: "fa fa-github",
    },
    {
      name: "instagram",
      url: "https://www.instagram.com/sudarshan_42/",
      className: "fa fa-instagram",
    },
  ],
  aboutme: `As a highly motivated and skilled software developer, I have a strong passion for the field that drives me to excel in my 
    technical abilities. I am a quick learner with a growth mindset, constantly seeking out opportunities 
    to expand my skill set and stay ahead of industry trends. In addition to my technical abilities, I am a team player who thrives in a 
    collaborative environment and values learning and growth. I am eager to collaborate with like-minded individuals and organizations
    to harness the power of technology for social good and make a significant impact.`,
  professions: [
    ["Web Development", "images/website-development.svg"],
    ["Mobile Development", "images/mobile-development.svg"],
  ],
  address: "Canada",
  website: "https://rbhatia46.github.io",
  education: [
    {
      UniversityName: "Lambton College",
      specialization: "Computer Software and Database Development, Toronto",
      MonthOfPassing: "",
      YearOfPassing: "2023",
      Achievements: "GPA: 3.66",
    },
    {
      UniversityName: "Tribhuvan University",
      specialization:
        "Bachelors of Science in Computer Science and Information Technology",
      MonthOfPassing: "",
      YearOfPassing: "2019",
      Achievements: "Percentage: 80%",
    },
  ],
  work: [
    {
        CompanyName: "Fin Investments Inc.",
        specialization: "Web Developer Co-op",
        MonthOfLeaving: "",
        YearOfLeaving: "May 2023 to Aug 2023",
        role: [
          `Modernized existing web features, introducing a revamped user interface (UI) and seamlessly migrating data to cloud-based solutions, enhancing overall user experience.`,
          `Employed Docker to containerize both frontend and API layers, facilitating seamless deployment, scalability, and maintenance of applications, resulting in a 20% reduction in deployment time.`,
          `Implemented persistent sorting functionality, reducing data retrieval time by 30%, and streamlining the data management process for users.`,
          `Designed and implemented modularized React components, resulting in a more maintainable and testable codebase.`,
          `Created component usage and API integration documentation, empowering the team to easily understand and leverage them.`,
        ],
      },
    {
      CompanyName: "Codyssey Web Nepal",
      specialization: "Full Stack Developer",
      MonthOfLeaving: "",
      YearOfLeaving: "Sep 2018 to Jan 2022",
      role: [
        ` Participated in the full software development cycle resulting in the successful deployment of software
solutions that met client requirements and increased their efficiency and satisfaction.
`,
        ` Developed and maintained REST APIs in an Agile environment using Ruby on Rails, leading to the successful
timely deployment of projects and an improvement of 25% in overall project delivery time.
`,
        ` Developed a map-based system utilizing RGeo, PostGIS, and OpenLayers, improving the ability to visualize
and analyze location-based data. Resulting in a significant increase of 40% in the efficiency of data analysis
and decision-making processes.
`,
        ` Created new features on the front end using React Hooks, Redux, React Router, and Ant Design, resulting in
an increase in user engagement by 30%.
`,
        ` Successfully implemented automation testing using RSpec, and JMeter tools, significantly reducing the
reliance on manual testing and improving the overall efficiency of the testing process.
`,
        ` Implemented continuous integration and deployment pipeline using Jenkins and Docker to automate the
build, test, and deployment process and ensure a faster time-to-market for software releases.
`,
        ` Designed and developed multiple databases using UML tools and custom SQL queries to support business
operations, resulting in improved data management, reporting accuracy, and informed decision making.`,
      ],
    },
  ],
  skills: {
    React: 95,
    "Ruby on Rails": 95,
    "React Native": 90,
    JavaScript: 95,
    Ruby: 95,
    PostgreSQL: 90,
    HTML5: 95,
    CSS3: 95,
    Git: 90,
    AWS: 80,
  },
  portfolio: [
    {
      name: "Sorting Visualizer",
      description: `Website to visualize different sorting algorithms.`,
      imgurl: "images/sort-visualizer.png",
      link: "https://sudarshanuprety42.github.io/sorting-visualizer/",
    },
    {
      name: "Path Finder",
      description:
        "Website to visualize popular path finding algorithms like BFS, DFS, Dijkstra’s, and A*",
      imgurl: "images/path-finder.png",
      link: "https://sudarshanuprety42.github.io/PathFindingVisualizer/",
    },
    {
      name: "SPOTNPARK",
      description:
        "Web site and Mobile to book parking spots within Kathmandu city.",
      imgurl: "images/spotnpark.png",
      link: "https://spotnparknepal.com",
    },
    {
      name: "Timetable Generation using Genetic algorithm.",
      description:
        "System inspired by genetic algorithm to resolve the NP problem of schedule generation.",
      imgurl: "images/timetable.jpg",
      link: "https://github.com/sudarshanuprety42/Timetable-Genetic-Algorithm",
    },
  ],
  testimonials: [
    {
      description: "This is a sample testimonial",
      name: "Some technical guy",
    },
    {
      description: "This is a sample testimonial",
      name: "Some technical guy",
    },
  ],
};

export default resumeData;
