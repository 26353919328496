import React, { useState, useRef } from "react";
import emailjs from 'emailjs-com';
import toast from 'react-hot-toast';
import Loader from "./Loader";
import { Zoom, Fade, Slide } from "react-reveal";


const Contact = ({ data }) => {
  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const form = useRef();

  if (data) {
    var {name, country, city, state, phone, email, contactMessage} = data;
  }

  const resetForm = () => {
    setUserName("");
    setUserEmail("");
    setSubject("");
    setMessage("");
  }
  const submitForm = (e) => {
    e.preventDefault();
    if(!userName || !userEmail || !message){
      toast.error("Please fill all required feilds.")
    }
    else{
      setLoading(true);
      emailjs.sendForm('service_gzmxfc5', 'template_tx5xjlg', form.current, 'zZ6Jr4P8_aBQ73KAb')
        .then((result) => {
            resetForm();
            toast.success("Message Sent Successfully!");
          }, (error) => {
            toast.error("Unable to send message try again later");
            // alert(error.text);
        }).finally(() => setLoading(false)
        );
    }
  };

  return (
    <section id="contact">
      {loading && <Loader />}
      <div className="row section-head">
        <div className="two columns header-col">
      <Fade left>
          <h1>
            <span>Get In Touch.</span>
          </h1>
      </Fade>
        </div>
        <div className="ten columns">
          <p className="lead">{contactMessage}</p>
        </div>
      </div>

      <div className="row">
        <div className="eight columns">
          <form onSubmit={submitForm} ref={form}>
            <fieldset>
              <Fade cascade>
              <div>
                <label htmlFor="user_name">
                  Name <span className="required">*</span>
                </label>
                <input
                  type="text"
                  defaultValue=""
                  value={userName}
                  size="35"
                  id="contactName"
                  name="user_name"
                  onChange={(e) => setUserName(e.target.value)}
                />
              </div>

              <div>
                <label htmlFor="user_email">
                  Email <span className="required">*</span>
                </label>
                <input
                  type="text"
                  defaultValue=""
                  value={userEmail}
                  size="35"
                  id="contactEmail"
                  name="user_email"
                  onChange={(e) => setUserEmail(e.target.value)}
                />
              </div>

              <div>
                <label htmlFor="contact_subject">Subject</label>
                <input
                  type="text"
                  defaultValue=""
                  value={subject}
                  size="35"
                  id="contactSubject"
                  name="contact_subject"
                  onChange={(e) => setSubject(e.target.value)}
                />
              </div>

              <div>
                <label htmlFor="contactMessage">
                  Message <span className="required">*</span>
                </label>
                <textarea
                  cols="50"
                  rows="15"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  id="contactMessage"
                  name="message"
                ></textarea>
              </div>

              <div>
                <button onClick={submitForm} type="submit" className="button submit">
                  Submit
                </button>
              </div>
              </Fade>
            </fieldset>
          </form>

          <div id="message-warning"> Error boy</div>
          <div id="message-success">
            <i className="fa fa-check"></i>Your message was sent, thank you!
            <br />
          </div>
        </div>

        <aside className="four columns footer-widgets">
          <div className="widget widget_contact">
            <Fade><h4>Address and Phone</h4></Fade>
            <Fade bottom>
            <p className="address">
              {name}
              <br />
              {email}
              <br />
              <br />
              {city} <br />
              {state}, {country}
              <br />
              <span>{phone}</span>
            </p>
            </Fade>
          </div>
        </aside>
      </div>
    </section>
  );
};

export default Contact;