import React, { Component } from "react";
import { Fade, Slide } from "react-reveal";

export default class Resume extends Component {
    render() {
        let resumeData = this.props.resumeData;
        return (
            <section id="resume">
                <div className="row education">
                    <Fade>
                        <div className="three columns header-col">
                            <h1>
                                <span>Education</span>
                            </h1>
                        </div>
                    </Fade>

                        <div className="nine columns main-col">
                            {resumeData.education &&
                                resumeData.education.map((item) => {
                                    return (

                                        <div
                                            className="row item"
                                            key={item.UniversityName}
                                        >
                                            <Fade>
                                            <div className="twelve columns">
                                                <h3>{item.UniversityName}</h3>
                                                <p className="info">
                                                    {item.specialization}
                                                    <span>&bull;</span>{" "}
                                                    <em className="date">
                                                        {item.MonthOfPassing}{" "}
                                                        {item.YearOfPassing}
                                                    </em>
                                                </p>
                                                <p>{item.Achievements}</p>
                                            </div>
                                            </Fade>
                                        </div>
                                    );
                                })}
                        </div>
                </div>
                <div className="row work">
                <Fade>
                    <div className="three columns header-col">
                        <h1>
                            <span>Work</span>
                        </h1>
                    </div>
                </Fade>
                    <div className="nine columns main-col">
                        {resumeData.work &&
                            resumeData.work.map((item) => {
                                return (
                                    <div
                                        className="row item"
                                        key={item.CompanyName}
                                    >
                                        <div className="twelve columns">
                                            <Fade><h3>{item.CompanyName}</h3>
                                            <p className="info">
                                                {item.specialization}
                                                <span>&bull;</span>{" "}
                                                <em className="date">
                                                    {item.MonthOfLeaving}{" "}
                                                    {item.YearOfLeaving}
                                                </em>
                                            </p>
                                            </Fade>
                                            <div>
                                                <Fade right cascade>
                                                <ul>
                                                    {item.role.map(
                                                        (role, index) => (
                                                            <li key={index}>
                                                                {role}
                                                            </li>
                                                        )
                                                    )}
                                                </ul>
                                                </Fade>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                    </div>
                </div>

                <div className="row skill">
                    <div className="three columns header-col">
                        <Fade>
                            <h1>
                                <span>Skills</span>
                            </h1>
                        </Fade>
                    </div>

                    <div className="nine columns main-col">
                        <div className="skills_list row">
                            {Object.entries(resumeData.skills).map(
                                ([key, value], index) => {
                                    return (
                                        <div
                                            className="skill_container"
                                            key={key}
                                        >
                                            <div className="skill_name">
                                                {key}
                                            </div>
                                            <Slide left delay={index*60}>
                                            <div
                                                className="skill_percent"
                                                style={{
                                                    width: `calc(${value}% - 120px)`,
                                                }}
                                            >
                                                {value}%
                                            </div>
                                            </Slide>
                                        </div>
                                    );
                                }
                            )}
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
