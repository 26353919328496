import React, { useEffect, useState } from "react";
import "./App.css";
import Header from "./components/Header";
import About from "./components/About";
import Resume from "./components/Resume";
import Portfolio from "./components/Portfolio";
import Testimonials from "./components/Testimonials";
import Footer from "./components/Footer";
import resumeData from "./resumeData";
import Contact from "./components/Contact";
import Loader from "./components/Loader";
import { Toaster } from 'react-hot-toast';

function App() {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(false);
    }, []);

    return (
            <div className="App">
                <Toaster />
                {loading && <Loader />}
                <Header resumeData={resumeData} />
                <About resumeData={resumeData} />
                <Resume resumeData={resumeData} />
                <Portfolio resumeData={resumeData} />
                {/* <Testimonials resumeData={resumeData} /> */}
                <Contact data={resumeData} />
                <Footer resumeData={resumeData} />
            </div>
    );
}

export default App;
