import React from "react";
import {Zoom, Fade} from "react-reveal";
import Typist from "react-typist";
import AnimParticle from "./AnimParticle";

export default function Jumbotran() {
    // const matches = useMediaQuery("(max-width:678px)");

    return (
        <>
            <div
                className="carrousel_image"
                style={{
                    minHeight: `${window.innerHeight}px`,
                }}
            >
                <AnimParticle />
                <div className="banner_container">
                    <Zoom>
                        <p>Hi, I'm</p>
                        <p className="my-data">Sudarshan Uprety</p>
                        <p>A</p>
                        <p className="my-data">
                            <Typist
                                avgTypingDelay={60}
                                cursor={{
                                    hideWhenDone: true,
                                    blink: false,
                                    hideWhenDoneDelay: 100,
                                }}
                                startDelay={650}
                            >
                                Software Developer
                            </Typist>
                        </p>
                    </Zoom>
                    <Fade bottom delay={2000}>
                    <a className="smoothscroll mouse_link" href="#about">
                        <span className="mouse_scroll"></span>
                    </a>
                    </Fade>
                </div>
            </div>
        </>
    );
}
