import React, { Component } from "react";
import { Zoom, Fade } from "react-reveal";
export default class About extends Component {
    render() {
        let resumeData = this.props.resumeData;
        return (
            <section id="about">
                <div className="about-container">
                    <Zoom>
                        <div className=" profile-container">
                            <img
                                className="profile-pic"
                                src="images/profile (6).jpeg"
                                alt=""
                            />
                            <h2>SUDARSHAN</h2>
                        </div>
                    </Zoom>
                    <div className="about-detail">
                        <Fade>
                            <h2>About Me</h2>
                            <p>{resumeData.aboutme}</p>
                            <br />
                        </Fade>
                        <div>
                            <Zoom>
                                <h2>What I do ?</h2>
                            </Zoom>
                            <div className="profession-container">
                                <Fade right cascade>
                                    <ul>
                                        {resumeData.professions.map(
                                            (profession) => (
                                                <li key={profession[0]}>
                                                    <img src={profession[1]} />
                                                    <div>{profession[0]}</div>
                                                </li>
                                            )
                                        )}
                                    </ul>
                                </Fade>
                            </div>
                        </div>
                        <div className="contact-me-container">
                            <Zoom duration={300} delay={500}>
                                <a
                                    className="button smoothscroll"
                                    href="#contact"
                                    role="button"
                                >
                                    Contact Me
                                </a>
                            </Zoom>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
