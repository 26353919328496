import React from 'react';
import { Zoom, Fade, Slide } from "react-reveal";

const NavBar = () => {
    return (
        <nav id="nav-wrap">
                        <a
                            className="mobile-btn"
                            href="#nav-wrap"
                            title="Show navigation"
                        >
                            Show navigation
                        </a>
                        <a
                            className="mobile-btn"
                            href="#"
                            title="Hide navigation"
                        >
                            Hide navigation
                        </a>
                        <Fade delay={3} duration={500} top>

                        <ul id="nav" className="nav">
                            <li className="current">
                                <a className="smoothscroll" href="#home">
                                    Home
                                </a>
                            </li>
                            <li>
                                <a className="smoothscroll" href="#about">
                                    About
                                </a>
                            </li>
                            <li>
                                <a className="smoothscroll" href="#resume">
                                    Resume
                                </a>
                            </li>
                            <li>
                                <a className="smoothscroll" href="#portfolio">
                                    Works
                                </a>
                            </li>
                            {/* <li>
                                <a
                                    className="smoothscroll"
                                    href="#testimonials"
                                >
                                    Testimonials
                                </a>
                            </li> */}
                            <li>
                                <a className="smoothscroll" href="#contact">
                                    Contact
                                </a>
                            </li>
                        </ul>
                        </Fade>
                    </nav>
    );
}

export default NavBar;
